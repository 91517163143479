import Popover from "@material-ui/core/Popover";
import React, {useState} from 'react';

function Region(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    var RegionDisplay = get_region_values(props)


    return <>
        {/*class for the Enviroment Label*/}    
        <div className="row">
            <div className="col-sm-1 col-md-3"></div>
            <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                <label className="input_label" forhtml="TemplateId">Region <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
           <div style={{padding:"12px"}}>
                <div> Use this option to select which region </div>
                <div> labs needs to be deployed </div>                
                </div>
    
      </Popover></label>
            </div>
            {/*class for the Enviroment selection/ option oid = "italic"*/}
            <div className="col-7 col-sm-4 col-md-3 col-lg-3 col-xl-3">
                <select className="italic form-control"  name="Region" id="Region"  required 
                onChange={(e) => {props.setRegionSelected(e.target.value)}} >
                    {RegionDisplay.map((reg, index) => (                  
                            <option value={reg.value}>{reg.name}</option>
                        ))}
                  
                </select>
            </div>
        </div>
           
    </>
}
function get_region_values(props)
{
  var jsonData =props.environemntRecords
  const Region = [{
        name:"us-east-2",
        value : "us-east-2"
  }];

  //Temp code to allow only Innovation Lab groups to view other region
  // Catch error that can be caused by a null user_email 
  for( var i =0; i < jsonData.Count; i++)
  {
      if(jsonData.Items[i].template_id=="0")
      {
          if ((jsonData.Items[i].region_admins).length> 0)
          {
            try{
                var lower_case_email = props.userEmail.toLowerCase();
            }
            catch{
              return Region;
            }
            if(jsonData.Items[i].region_admins.includes(lower_case_email)){
              var allregion= jsonData.Items[i].Region
              console.log("region",allregion)
              for(var index in allregion){
                  console.log("region",allregion[index])
                  Region.push({
                      name:allregion[index],
                      value :allregion[index],
                  })
              }            
              return Region;
            }
          }   
      }
  }
////////////////////////////////////////////////////////////////
//Uncomment below code when region can be shown to eveyone

  // for( var i =0; i < jsonData.Count; i++)
  //   {
  //       if(jsonData.Items[i].template_id=="0")
  //       {
  //           var allregion= jsonData.Items[i].Region
  //           console.log("region",allregion)
  //           for(var index in allregion){
  //               console.log("region",allregion[index])
  //               Region.push({
  //                   name:allregion[index],
  //                   value :allregion[index],
  //               })
  //           }            
  //           return Region;       
  //       }
  //   }
  return Region;
}

export default Region;


