import React, {useState, useEffect} from 'react';
import DesktopNumber from './DesktopNumber';
import Popover from "@material-ui/core/Popover";

function DesktopRequired(props) {
    
    const [displayDesktopNumber, setDisplayDesktopNumber] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // After every render, if the selected environment changes, check if the environment needs desktops or not.
    // Resets and hides the DesktopNumber component if a technical environment is selected
    useEffect(() => {
        if(display_desktop_inputs(props) === false){
            setDisplayDesktopNumber(false);
        }  
    })

    // Hide DesktopRequired component if the current environment is not an application deployment
    if(display_desktop_inputs(props) === false){
        return null
    }

    return<>

        <div className="row" id="DesktopRequiredRow">
            <div className="col-sm-1 col-md-3"></div>
            <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                <label className="input_label1" forhtml="DesktopRequired">Additional Desktops? <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>This option determines how many </div>
                <div>Windows Desktops your lab will </div>
                <div>be supplied with. These can be </div>
                <div>assigned to training delegates</div>
                <div>or used for testing.</div></div>

        </Popover></label>
            </div>
            {/* class for the Desktop Required selection with =id "location" */}
            <div className="col-7 col-sm-4 col-md-3">
                <select className="input1 form-control" 
                        id="DesktopRequired" 
                        name="DesktopRequired"
                    onChange={() => setDisplayDesktopNumber(!displayDesktopNumber)}>
                    <option value="no">No </option>
                    <option value="yes">Yes </option>
                </select>
            </div>
        </div>
        <DesktopNumber displayDesktopNumber={displayDesktopNumber}/>
    </>

        
    
    
}
export default DesktopRequired

function display_desktop_inputs(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Desktop_Required === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
