import React from 'react';

//<Header title="Verint Lab Request form" imgSrc="/images/demo_iconcopy.svg" width="70" height="60"/>

function Header({title, imgSrc}) {
    return  <h2>
                <span>
                    <img  class="header_image" src={imgSrc} alt="Verint Title" width="70" height="60" />
                </span> 
                {title}
            </h2>    
              
  }

Header.defaultProps = {
    title: 'Verint',
}

export default Header;