import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

function LiveRecordingCheckbox(props) {
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_lr_checkbox(props) === false){
            props.setLiveRecording(false)
        }        
    }, [props.environmentSelected])
     
    useEffect(() => {
        setIsDisabled(props.awsliveRecording);
    }, [props.awsliveRecording]);
     // If selected environment is cannot use the checkbox, hide
    //  if(display_lr_checkbox(props.environmentSelected) === false){
    //     return null
    // }
     // If selected environment is cannot use the checkbox, hide
     if(should_display_lr_checkbox(props) === false){
        return null
    }

    return <>
        {/*  Live Recording  */}
        <div style={{}} className="row" id= "LiveRecordingRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="LiveRecording_label"className="input_label1" forhtml="LiveRecording">Live Recording <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Select this option if you require the </div>
                <div>ability to make test calls with an </div>
                <div>Avaya Aura telephony system.</div></div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="LiveRecording" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                       name="LiveRecording" type = "checkbox" value = {props.liveRecording}  disabled={isDisabled} checked ={props.liveRecording}
                       onChange={(e) => {props.setLiveRecording(!props.liveRecording);}}/>

                </div>
            </div>
    </>        
}

export default LiveRecordingCheckbox;

function should_display_lr_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Live_Recording  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

// function display_lr_checkbox(template_id){
//     // A list of template ids for template ids that can deploy this checkbox
//     var allowed_template_ids = ['61', '35', '64', '34', '46', '19', '42', '56', '57',
//         '58', '26', '1', '81', '82', '72', '87', '85', '84', '65'];
    
//     // If current template_id is in the list of template ids, display checkbox
//     if(allowed_template_ids.includes(template_id)){
//         return true
//     }
//     else{
//         return false
//     }

// }