import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

/* Set the value of EmployeeTimecollector checkbox */
function EmployeeTimecollector(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(display_EmployeeTimecollector(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    // If selected environment is cannot use the checkbox, hide
    if(display_EmployeeTimecollector(props) === false){
        return null
    }

    return <>
        {/*  EmployeeTimecollector Checkbox  */}
        <div className="row" id= "EmployeeTimecollectorRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="EmployeeTimecollector_label"className="input_label1" forhtml="EmployeeTimecollector"> Employee Time collection API <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Select this option to deploy the Kafka Employee Time </div>
                <div>Collection API. Note: That this will be enabled post</div>
                <div>deployment of your lab and you will be notified once</div>
                <div>it is actioned.</div></div>

        </Popover></label>
                </div>
                
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="EmployeeTimecollector" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                        name="EmployeeTimecollector" type = "checkbox" value = {checked}  onChange={(e)=>{setChecked(!checked)}}  />
                </div>
            </div>
    </>    
}

export default EmployeeTimecollector;

function display_EmployeeTimecollector(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].EmployeeTimecollector === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
