import React, {useState} from 'react';
import Popover from "@material-ui/core/Popover";
import { Alert } from '@mui/material';
import LoadingSpinner from "./LoadingSpinner";

function UrlName(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [UrlName, setUrlName] = useState(null)
    const [value, setValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const color = value == "available" ? 'green' : value == "unavailable" ? 'red' : 'black';

    // TODO: Make function to determine URL name
    if(props.urlchecked === false){
        return null
    } 

    // Check if the values are unavailable or null
    if (value != 'available'|| value == null ) 
    {
        props.setValidationError("error");
    } 
    else 
    {
        props.setValidationError("available");
    };


    function shortname(inShortName,intoken,e){  
        // In order to stop the page from reloading 
        e.preventDefault();
        //Alert for special characters 
        var allowedChars = /^[a-zA-Z0-9]*$/;
        var shortName= inShortName 
        if (!allowedChars.test(shortName)) {
            {
                setValue(<Alert severity="error">Please note special characters are not allowed  </Alert>)
                return false;
            }
        }
        //To load the spinning icon
        setIsLoading(true)
        if(shortName){}   
        var options = 
        {
          method: "POST",
          headers: {'Content-Type' : 'application/json'},
          body: `{"shortName" : "${shortName}", "token" : "${intoken}"}`
        }

        //const request = new Request('http://localhost:3004/friendlyURL',options);
        const request = new Request("https://"+window.location.hostname+'/friendlyURL',options);
        fetch(request)
        .then(response => response.json())
        .then(data => {
            setValue(data);
            setIsLoading(false);
          })
    
    }
    return <>
        {/* URL anme selection */}
        <div className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <label id="UrlName_label"className="input_label1" forhtml="UrlName">Short Name <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <div style={{padding:"12px"}}>
                <div> Max 10 characters, special characters </div>
                <div> aren't allowed and only provide the</div>
                <div> short name.</div>
                <div> Example: telcomind would provision </div>
                <div> as telcomind.verint.live</div></div>
    
      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-10">
                    <input type="search" placeholder="Search..." id="UrlName" name='UrlName' 
                        style={{ border: '1px solid #ccc', borderRadius: "4px", width: "87%",padding:"0.376rem 0.75rem", name: "UrlName", required: "required"}} 
                        value={UrlName} maxLength="10"
                        onChange={(e)=>{setUrlName(e.target.value);}} 
                        required />
                       <button type="search" style={{background:"#ccc", width:"33px",height:"38px", border:"1px solid #ccc"}} onClick={e =>{shortname(UrlName,props.token,e)}}><i class="fa fa-search"></i></button>
                       {/* // Alert added if the value is unavailable or null */}
                       {props.ValidationError!= 'available' && props.showAlert && <div sx={{ position: 'relative', ml: 2 }}>
                        <Alert severity="warning" sx={{ position: 'absolute',  width:'100%', top : '30%', left:'95%', transform: 'translateY(-50%)' }}>
                         Enter Valid ShortName
                        </Alert>
                      </div>}
                       <span style={{color: color, fontWeight:'bold'}}>{isLoading ? <LoadingSpinner /> : value}</span>
                       
                       
                </div>
            </div>
    </>       
}

export default UrlName;