import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

function VTACheckbox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(should_display_vta_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    // If selected environment is cannot use the checkbox, hide
    // if(display_vta_checkbox(props.environmentSelected, props.userEmail) === false){
    //     return null
    // }
    if(should_display_vta_checkbox(props) === false){
        return null
    }

    return <>
            {/*  Mobile Gateway  */}
            <div className="row" id="VTARow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="VTA_label"className="input_label1" forhtml="VTA">Text Analytics <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Select this option if you require </div>
                <div>'Text Analytics' capability. When </div>
                <div>selecting this option and starting  </div>
                <div>the lab, leave the lab running for </div>
                <div>four hours, to allow Text data </div>
                <div>propagtion.</div></div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="VTA" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                       name="VTA" type = "checkbox" value = {checked}  
                       onChange={(e) => {setChecked(!checked);}}/>
                </div>
            </div>
    </>        
}

export default VTACheckbox;
//Validate if current user is part of admin emails 
function get_admin_emails(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {        
        if(jsonData.Items[i].template_id === "0" )
        {                  
         
            // Catch error that can be caused by a null user_email 
            try{
                var lower_case_email = props.userEmail.toLowerCase();
            }
            catch{}
            if(jsonData.Items[i].vta_admin_emails.includes(lower_case_email)){
                return true
            }
            else 
            {
                return false
            }
        }
    }
}
function should_display_vta_checkbox(props)
{
    // temp code to enable VTA checkbox 
    var temp_allowed_template_ids = ['34'];
    // A list of admin that can deploy LMS environments from the request form
    var admin_emails = ['amit.rao@verint.com', 'matt.anscombe@verint.com', 
        'ullas.gowda@verint.com'
    ];
    
    // Catch error that can be caused by a null user_email 
    try{
        var lower_case_email = props.userEmail.toLowerCase();
        if(temp_allowed_template_ids.includes(props.environmentSelected)  &&  get_admin_emails(props)){
            return true
        }
    }
    catch{}
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].vta_checkbox  === true ){
                return true
            }
            else 
            {
                return false
            }
        }      
    }
    return false
}
// function display_vta_checkbox(template_id, user_email){
//     console.log(`DELETE ME | template_id: ${template_id} | user_email: ${user_email}`)
//     // A list of template ids for template ids that can deploy this checkbox
//     var temp_allowed_template_ids = ['84', '34'];
//     var allowed_template_ids = ['87','84'];
//     // A list of admin that can deploy LMS environments from the request form
//     var admin_emails = ['amit.rao@verint.com', 'matt.anscombe@verint.com', 
//         'ullas.gowda@verint.com'
//     ];
    
//     // Catch error that can be caused by a null user_email 
//     try{
//         var lower_case_email = user_email.toLowerCase();
//         if(temp_allowed_template_ids.includes(template_id)  && admin_emails.includes(lower_case_email)){
//             return true
//         }
//     }
//     catch{}

//     // If current template_id is in the list of template ids, display checkbox
//     if(allowed_template_ids.includes(template_id) ){
//         return true
//     }
//     else{
//         return false
//     }

// }