import Popover from "@material-ui/core/Popover";
import React, {useState} from 'react';

function Environment(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    //fetchRows("COE");
    //var environmentDisplay = fetchRows("COE");
    // console.log( "get_environment_display: "+get_environment_display(props.group))
    // var environmentDisplay = get_environment_display(props.group)
    var environmentDisplay = get_environemnt_values(props)
    // console.log ("environemntRecords",props.environemntRecords)
    // console.log("environmentDisplay",environmentDisplay)
    console.log("Region Selected:",props.regionSelected)
    return <>
        {/*class for the Enviroment Label*/}    
        <div className="row">
            <div className="col-sm-1 col-md-3"></div>
            <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                <label className="input_label" forhtml="TemplateId">Environment <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
           <div style={{padding:"12px"}}>
                <div> Use this option to select what type of lab </div>
                <div> you wish to deploy. For example : BLUE </div>
                <div> (simulates an 'On Premise' environment) or  </div>
                <div> EARTH (simulates a 'Cloud' environment). We  </div>
                <div> have many application and technical labs to </div>
                <div> chose from.</div></div>
    
      </Popover></label>
            </div>
            {/*class for the Enviroment selection/ option oid = "italic"*/}
            <div className="col-7 col-sm-4 col-md-3 col-lg-3 col-xl-3">
                <select className="italic form-control" name="TemplateId" id="TemplateId" required
                    onChange={(e) => {props.setEnvironmentSelected(e.target.value)}}>
                    {environmentDisplay.map((env, index) => (                  
                            <option value={env.template_id}>{env.template_name}</option>
                        ))}
                  
                </select>
            </div>
        </div>
           
    </>
}
function get_environemnt_values(props)
{
  var jsonData =props.environemntRecords
  const environment = [{
        template_id:"",
        template_name : "",
        order : 0
  }];
  for( var i =0; i < jsonData.Count; i++)
        {
            if(props.regionSelected != "us-east-2" &&  props.regionSelected != null )
            {
                if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].Region != undefined 
                && jsonData.Items[i].Region.includes(props.regionSelected))
                {
                    //console.log("this groupName:" + jsonData.Items[i].template_id);              
                
                    environment.push({
                        template_id:jsonData.Items[i].template_id,
                        template_name : jsonData.Items[i].template_name,
                        order :  jsonData.Items[i].order
                    })
                    environment.sort((a, b) => a.order - b.order )
                    //console.log("environmentDisplay"+ environment.sort((a, b) => a.order - b.order ))
                }
            }
            else{
                if(jsonData.Items[i].group_name.includes(props.group))
                {
                    //console.log("this groupName:" + jsonData.Items[i].template_id);              
                
                    environment.push({
                        template_id:jsonData.Items[i].template_id,
                        template_name : jsonData.Items[i].template_name,
                        order :  jsonData.Items[i].order
                    })
                    environment.sort((a, b) => a.order - b.order )
                    //console.log("environmentDisplay"+ environment.sort((a, b) => a.order - b.order ))
                }
            }
        }
    return environment;
}

export default Environment;


