import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

function DesktopNumber(props) {

    const [numberOfDesktops, setNumberOfDesktops] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // Removes the old DesktopNumber component and resets its value to 0 whenever the props.desktopRequired changes.
    useEffect(() => {
        return () => {
            setNumberOfDesktops(0)
        }
    }, [props.displayDesktopNumber])

    // If component is not required, remove it from the form
    if(props.displayDesktopNumber === false){
        return null
    }

    return(
        <div className="row" style={{}} id="NumberOfDesktopsRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2" style={{padding: '10px'}}>
                    <label className="input_label1" forhtml="NumberOfDesktops" 
                        style={{ borderRadius: '10px'}}>Number of Desktops <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}></i><Popover 
                          anchorEl={anchorEl}
                          open={open}
                          id={open ? "simple-popover" : undefined}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "top",
                            
                          }}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                          }}
                          >       <div style={{padding:"12px"}}>
                                  <div>Use this option to select whether </div>
                                  <div>or not you require Desktop PODS to  </div>
                                  <div>be created.</div></div>
                  
                          </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3">
                    <input className="input1 form-control" type="number" id="NumberOfDesktops" min="0" max="20" name="NumberOfDesktops" 
                    onChange={(e)=> setNumberOfDesktops(e.target.value) } value={numberOfDesktops}
                    />
                </div>
            </div>
    )
}
export default DesktopNumber

