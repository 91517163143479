import React, {useState} from 'react';
import Popover from "@material-ui/core/Popover";

function InternalId(props) {
    
    const [internalId, setInternalId] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // TODO: Make function to determine internalId label

    return <>
        {/* Internal Id selection */}
        <div className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="internalID_label"className="input_label1" forhtml="internalID">{determine_field_label(props.group)} <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <div style={{padding:"12px"}}>
                <div> For a demo please put the SFID and</div>
                <div> prospective customer. For billable </div>
                <div> customer training put the SO number </div>
                <div> and for everything else add a short,</div>
                <div> yet descriptive reason. For example: </div>
                <div>'Testing Feature X' or 'Internal Training</div>
                <div> Demo Session'.</div></div>
    
      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="input1 form-control" id="internalID" name='TrackingId'
                        style={{ border: '1px solid #ccc', borderRadius: "4px", width: "150", name: "TrackingId", required: "required"}} 
                        value={internalId}
                        onChange={(e)=>{setInternalId(e.target.value);}}
                        required
                        />
                </div>
            </div>
    </>       
}

export default InternalId;


function determine_field_label(group){
    // A list of template ids for template ids that can deploy this checkbox
    if (group === "PreSales" || group === "APAC" || group === "EMEAPreSales" || group === "APACPreSales")
        return ("SFDC ID");
    else if (group == "ISV")    
        return("Partner Name");
    else if (group === "VCS")
        return("Customer");
    else 
        return("Internal ID (SO#)");
   
}