import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Popover from "@material-ui/core/Popover";
import React, {useState} from 'react';

function StartDate(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return <>
        {/* Start date selection */}
        <div className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label className="input_label1" forhtml="startDate">Start Date <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
           <div style={{padding:"12px"}}>
                <div> The date that your lab will be deployed.</div>
                <div> Setting a date in the future, will result</div>
                <div> in your lab email being delivered at a </div>
                <div> later date. </div></div>
    
      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <DatePicker startDate={props.startDate} setStartDate = {props.setStartDate} endDate={props.endDate}
                        dateFormat='yyyy-MM-dd' uiLibrary='bootstrap4' iconsLibrary='fontawesome' 
                        minDate={new Date()} maxDate={props.endDate} selected={props.startDate}
                        onChange={(date) => { props.setStartDate(date)}}
                        className="input1 form-control" id="startDate" onKeyDown={() => {return false}} 
                        style={{background: "#FFFFFF", width: "150", required: 'required'}} 
                        name="ProvisionDate"
                        required
                        />
                </div>
            </div>
    </>        
}

export default StartDate;