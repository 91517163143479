import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Popover from "@material-ui/core/Popover";
import { React, useState } from 'react';
function EndDate(props) {

    const isAdmin = props.is_superAdmin;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const currentDate = new Date(props.startDate);
    const endDatevalue = new Date(props.endDate);
    const threeMonthsFromNow = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());  
    const maxDate = isAdmin ? null : threeMonthsFromNow; // If user is not an admin, set maxDate to 3 months from currentDate

    return <>
        {/* Start date selection */}
        <div className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label className="input_label1" forhtml="startDate">End Date <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
           <div style={{padding:"12px"}}>
                <div> The end date for your lab. After this date,</div>
                <div> the lab will no longer exist. Make a note</div>
                <div> of this date, and don't forget you can </div>
                <div> extend the lab via current requests. </div>
                <div> </div>
                <div> <b>Note :</b> That currently you can only deploy labs </div>
                <div> for a maximum of 3 months, should you </div>
                <div> need to extend this date beyond 3 months </div>
                <div> please reach out to your regional admin.</div>
        </div>
    
      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6" style={{ display: 'flex' }}>
                    <DatePicker endDate={props.endDate} setDate = {props.setEndDate} startDate={props.startDate} 
                        onChange={(date) =>{props.setEndDate(date)}}
                        dateFormat='yyyy-MM-dd' uiLibrary='bootstrap4' iconsLibrary='fontawesome' 
                        minDate={props.startDate} maxDate={maxDate} selected={props.endDate}
                        className="input1 form-control" id="startDate" onKeyDown={() => {return false}} 
                        style={{background: "#FFFFFF", width: "150", required: 'required'}} 
                        name="TeardownDate"
                        required
                        />   
                </div>
            </div>
    </>        
}

export default EndDate;