import React, {useEffect,useState} from 'react';
import Popover from "@material-ui/core/Popover";
function CommunityCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_Community_checkbox(props) === false){
            props.setcommunitySelected(false);
        }  
    })

    // If selected environment is cannot use the checkbox, hide the checkbox
    if(should_display_Community_checkbox(props) === false){
        return null
    }

    return <>
        {/*  Community Checkbox */}
        <div style={{}} className="row" id= "CommunityRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="Community_label"className="input_label1" forhtml="Community">Verint Community <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Select this option if you </div>
                <div>require an instance of </div>
                <div>Verint's Community product</div></div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="Community" style={{border: '1px solid #ccc', borderRadius: '4px'}}
                        name="Community" type = "checkbox" value = {props.communitySelected} 
                        onChange={(e) => {props.setcommunitySelected(!props.communitySelected);}}/>

                </div>
            </div>
    </>        
}

export default CommunityCheckbox;
function should_display_Community_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Verint_Community === true ){
                return true
            }
            else 
            {
                return false
            }
        }      
    }
    return false
}

