import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

function GivenTime(props) {

    const [hours, setHours] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // Removes the old GivenTime component and resets its value to 0 whenever the props.lmsSelected changes.
    useEffect(() => {
        return () => {
            setHours(0)
        }
    }, [props.lmsSelected])

    // If component is not required, remove it from the form
    if(props.lmsSelected === false){
        return null
    }

    return(<>
        {/*  Given Time Input */}
        <div className="row" style={{}} id="GivenTimeRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label className="input_label1" forhtml="GivenTime" 
                        style={{ borderRadius: '10px'}}>Given Time <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}></i><Popover 
                          anchorEl={anchorEl}
                          open={open}
                          id={open ? "simple-popover" : undefined}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "top",
                            
                          }}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                          }}
                        >
                          <div style={{padding:"12px"}}>
                                  <div> This option allows you to set an </div>
                                  <div> expiry time on the Lab request. </div>
                                  <div> For example, you can set your lab </div>
                                  <div> to expire after eight (8) hours </div>
                                  <div> have elapsed.</div></div>
                      
                      
                        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3">
                    <input className="input1 form-control" type="number" id="GivenTime" 
                        min='0' max="100" name="GivenTime" required
                        onChange={(e)=> setHours(e.target.value) } value={hours}
                    />
                </div>
            </div>
        </>
    )
}
export default GivenTime

 