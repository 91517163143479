import React, {useEffect,useState} from 'react';
import Popover from "@material-ui/core/Popover";

function ForwardEmail(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    ////If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_email_checkbox(props) === false){
            props.setForwardEmail(false)
        }        
    }, [props.environmentSelected])

     // If selected environment is cannot use the checkbox, hide
     //If selected environment is cannot use the checkbox, hide
     if(should_display_email_checkbox(props) === false){
        return null
    }
    
    return <>
        {/* Additional_recipient */}
        <div style={{}} className="row" id= "Additional_recipient">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="Additional_recipient_label"className="input_label1" forhtml="Additional_recipient">Additional Recipient <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <div style={{padding:"12px"}}>
                <div> Use this option to send the lab details </div>
                <div> to an additional user. An example of this</div>
                <div> could be to a partner. </div></div>

      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="Additional_recipient" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                       name="Additional_recipient" type = "checkbox" value = {props.forwardEmail}  
                       onChange={(e) => {props.setForwardEmail(!props.forwardEmail);}}/>
                      
                </div>           
            </div>
    </>      
}

export default ForwardEmail;

function should_display_email_checkbox(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Additional_recipient === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}