import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover"; 
import IntelligentForecastingCheckBox from './IntelligentForecastingCheckBox'; 
import CallSummaryCheckBox from './CallSummaryCheckBox';

/* Set the value of DaVinci checkbox */
function DaVinciCheckBox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const open = Boolean(anchorEl);
    let is_davinci = get_daVinci_templateId(props);
    
    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(should_display_DaVinci_checkbox(props) === false){
            setChecked(false)
        }
        if(is_davinci === true){
          setIsDisabled(true)
          setChecked(true)
        }
        else if(is_davinci === false){
          setIsDisabled(false)
          setChecked(false)
        }        
    }, [props.environmentSelected])

    // If selected environment is cannot use the checkbox, hide
    if(should_display_DaVinci_checkbox(props) === false){
        return null
    }
    
    return <>
        {/*  DaVinci Checkbox  */}
        <div className="row" id= "DaVinciRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="DaVinci_label" className="input_label1" forhtml="DaVinci">Da Vinci <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <div style={{padding:"12px"}}>
          <div> Select this checkbox if you require any of the following:   </div>
          <div> Identity, RTAA, Work Assist, AQM (AI) and Da Vinci  </div>
          <div> post call transcription. </div>
          <div> More features and services will be added soon. </div> 
            {/* <div> within Projects Rules Manager.</div> */}
        </div>
      </Popover></label>    
        </div>
          <div className="col-7 col-sm-4 col-md-3 col-xs-6">
              <input className="checkmark" disabled={isDisabled} id="DaVinci" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                  name="DaVinci" type = "checkbox" value = {checked} checked={checked} onChange={(e)=>{setChecked(!checked)}}  />
          </div>
        </div>
        <IntelligentForecastingCheckBox group={props.group} environemntRecords={props.environemntData} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked}></IntelligentForecastingCheckBox>  
        <CallSummaryCheckBox group={props.group} environemntRecords={props.environemntData} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked}></CallSummaryCheckBox>  
    </>      
}

export default DaVinciCheckBox;
function should_display_DaVinci_checkbox(props)
{
    var jsonData = props.environemntRecords
    for( var i = 0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].DaVinci_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

//Validate if templateId has preselected DaVinci 
function get_daVinci_templateId(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {        
        if(jsonData.Items[i].template_id === "0" )
        {                  
         
            // Catch error that can be caused by a null user_email 
            try{
                var daVinci_templateId = props.environmentSelected;
            }
            catch{}
            if(jsonData.Items[i].davinci_labs_templateIds.includes(daVinci_templateId)){
                return true
            }
            else 
            {
                return false
            }
        }
    }
}

// function display_DaVinci_checkbox(template_id){
//     // A list of template ids for template ids that can deploy this checkbox
//     var allowed_template_ids = ['62', '87'];
    
//     // If current template_id is in the list of template ids, display checkbox
//     if(allowed_template_ids.includes(template_id)){
//         return true
//     }
//     else{
//         return false
//     }

// }