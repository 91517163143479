import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

/* Set the value of Intelligent-Forecasting checkbox */
function IntelligentForecastingCheckBox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    useEffect(() => {
        if(should_display_intelligentForecasting_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    //If selected environment cannot use the checkbox, hide
    if(should_display_intelligentForecasting_checkbox(props) === false){
        return null
    }

    // If DaVinci Checkbox is set to true, only then display Intelligent forecasting Checkbox
        return <>
        {/*  Intelligent-Forecasting Checkbox  */}
        <div className="row" id= "IntelligentForecastingRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="intelligentForecasting_label"className="input_label1" forhtml="IntelligentForecasting">Intelligent Forecasting <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
            setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
            setAnchorEl(null);
        }}
        transformOrigin={{
            horizontal: "center",
            vertical: "top",
            
        }}
        anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
        }}
        >
        <div style={{padding:"12px"}}>
            <div> Select this option to include Intelligent Forecasting  </div> 
        </div>
        </Popover></label>
        </div>    
            <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                <input className="checkmark" id="IntelligentForecasting" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                name="IntelligentForecasting" type = "checkbox"  value = {checked}  onChange={(e)=>{setChecked(!checked)}} />
            </div>
        </div>
    </> 
}

export default IntelligentForecastingCheckBox;

function should_display_intelligentForecasting_checkbox(props)
{
    if(props.daVinciChecked === true){
        return true
    }
    return false
}