import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

function AWSLiveRecordingCheckbox(props) {
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_lr_checkbox(props) === false){
            props.setAwsliveRecording(false)
        }        
    }, [props.environmentSelected])

    useEffect(() => {
        setIsDisabled(props.liveRecording);
    }, [props.liveRecording]);

     // If selected environment is cannot use the checkbox, hide
       if(should_display_lr_checkbox(props) === false){
        return null
    }

    return <>
        {/* AWS  Live Recording  */}
        <div style={{}} className="row" id= "LiveRecordingRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2" style={{padding: '10px'}}>
                    <label id="AWSLiveRecording_label"className="input_label1" forhtml="AWSLiveRecording" style={{ borderRadius: '10px'}}>AWS Live Recording <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Select this option if you require the </div>
                <div>AWS LiveRecording</div></div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="AWSLiveRecording" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                       name="AWSLiveRecording" type = "checkbox" value = {props.awsliveRecording}  disabled={isDisabled} checked = {props.awsliveRecording}
                       onChange={(e) => {props.setAwsliveRecording(!props.awsliveRecording);}}/>

                </div>
            </div>
    </>        
}

export default AWSLiveRecordingCheckbox;

function should_display_lr_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].AWS_LiveRecording === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

