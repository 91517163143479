import { getScheduleDisplay } from '../initializeForm';
import Popover from "@material-ui/core/Popover";
import React, {useState} from 'react';

function TimeZone(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    //const [timezone, setTimeZone] = useState(null)
    var scheduleDisplay = getScheduleDisplay(props.group);

    return <>
        {/* {console.log("DEBUG | timezome: ", timezone)} */}
        {/* Class for timezone selection */}
        <div className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label className="input_label1" forhtml="ScheduleName">Time Zone   <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
           <div style={{padding:"12px"}}>
                <div >Select the time zone that is closest to your</div>
                <div> region. The timezone determines what time </div>
                <div> your lab session shuts down each day.</div></div>
                
      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-lg-3">
                    <select className="input1 form-control " name="ScheduleName" id="ScheduleName">
                        {scheduleDisplay}
                    </select>
                </div>
            </div>
    </>       
}

export default TimeZone;