    // _____________________________________________________________________setDisplay
    // Rewrites the choices of the TemplateId field based on the selected choice for the Search field 
    export function getEnvironmentDisplay(group) {
        var environmentDisplay = null
        switch (group) {
            case "COE":
                environmentDisplay = (
                    <>
                        <option value="" ></option>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="35" >Blue (ST) HFR5</option>
                        <option value="64" >ODF</option>
                        <option value="31" >Green (POC)</option>
                        <option value="34" >Demo</option>
                        <option value="38" >EM Demo</option>
                        <option value="41" >EFM</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="1" >Core Install</option>
                        <option value="26" >EMRM</option>
                        <option value="21" >Authentication</option>
                        <option value="49" >Security (TLS)</option>
                        <option value="10" >Security (KMS)</option>
                        <option value="8" >Text-Config</option>
                        <option value="3" >SAML</option>
                        <option value="15" >Speech Configuration</option>
                        <option value="46" >VAV Configuration - WFO</option>
                        <option value="44" >Core Support</option>
                        <option value="19" >DPA Configuration</option>
                        <option value="17" >WFM Configuration</option>
                        <option value="51" >PM Configuration</option>
                        <option value="42" >Rec & Arch Configuration</option>
                        <option value="28" >Advanced Support</option>
                        <option value="14" >V11 Migration</option>
                        <option value="45" >IEM Configuration</option>
                        <option value="52" >Breakfix</option>
                        <option value="54" >WM Installation</option>
                        <option value="56" >VAV Configuration - VFC</option>
                        <option value="57" >VFC</option>
                        <option value="58" >Advanced Support V2</option>
                        <option value="6" >L1 Class</option>
                        <option value="59" >VFC Latest Kit</option>
                        <option value="2" >15.1 to 15.2 SBS Migration</option>
                        <option value="60" >IvaPro</option>
                        <option value="81" >Dev Blue20200R1</option>
                        <option value="82" >Dev Earth2020R1</option>
                    </>
                );
                return environmentDisplay;
                

            case "Support":
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="35" >Blue (ST) HFR5</option>
                        <option value="64" >ODF</option>
                        <option value="31" >Green (POC)</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="1" >Core Install</option>
                        <option value="26" >EMRM</option>
                        <option value="21" >Authentication</option>
                        <option value="49" >Security (TLS)</option>
                        <option value="10" >Security (KMS)</option>
                        <option value="8" >Text-Config</option>
                        <option value="3" >SAML</option>
                        <option value="15" >Speech Configuration</option>
                        <option value="46" >VAV Configuration - WFO</option>
                        <option value="44" >Core Support</option>
                        <option value="19" >DPA Configuration</option>
                        <option value="17" >WFM Configuration</option>
                        <option value="51" >PM Configuration</option>
                        <option value="42" >Rec & Arch Configuration</option>
                        <option value="28" >Advanced Support</option>
                        <option value="14" >V11 Migration</option>
                        <option value="45" >IEM Configuration</option>
                        <option value="56" >VAV Configuration - VFC</option>
                        <option value="57" >VFC</option>
                    </>
                );
                return environmentDisplay;
                

            case "EMEA": //39 twice?
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="35" >Blue (ST) HFR5</option>
                        <option value="64" >ODF</option>
                        <option value="31" >Green (POC)</option>
                        <option value="38" >EM Demo</option>
                        <option value="41" >EFM</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="1" >Core Install</option>
                        <option value="26" >EMRM</option>
                        <option value="21" >Authentication</option>
                        <option value="49" >Security (TLS)</option>
                        <option value="10" >Security (KMS)</option>
                        <option value="8" >Text-Config</option>
                        <option value="3" >SAML</option>
                        <option value="15" >Speech Configuration</option>
                        <option value="46" >VAV Configuration - WFO</option>
                        <option value="44" >Core Support</option>
                        <option value="19" >DPA Configuration</option>
                        <option value="17" >WFM Configuration</option>
                        <option value="51" >PM Configuration</option>
                        <option value="42" >Rec & Arch Configuration</option>
                        <option value="28" >Advanced Support</option>
                        <option value="14" >V11 Migration</option>
                        <option value="45" >IEM Configuration</option>
                        <option value="56" >VAV Configuration - VFC</option>
                        <option value="57" >VFC</option>
                        <option value="58" >Advanced Support V2</option>
                        <option value="59" >VFC Latest Kit</option>
                        <option value="2" >15.1 to 15.2 SBS Migration</option>
                        <option value="54" >WM Installation</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="60" >IvaPro</option>
                    </>
                );
                return environmentDisplay;
                

            case "PreSales":
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="64" >ODF</option>
                        <option value="34" >Demo</option>
                        <option value="31" >Green (POC)</option>
                        <option value="38" >EM Demo</option>
                        <option value="41" >EFM</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="54" >WM Installation</option>
                        <option value="60" >IvaPro</option>
                    </>
                );
                return environmentDisplay;
                

            case "APAC":
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="64" >ODF</option>
                        <option value="34" >Demo</option>
                        <option value="65" >Korea Demo</option>
                        <option value="31" >Green (POC)</option>
                        <option value="38" >EM Demo</option>
                        <option value="41" >EFM</option>
                        <option value="1" >Core Install</option>
                        <option value="26" >EMRM</option>
                        <option value="21" >Authentication</option>
                        <option value="49" >Security (TLS)</option>
                        <option value="10" >Security (KMS)</option>
                        <option value="8" >Text-Config</option>
                        <option value="3" >SAML</option>
                        <option value="15" >Speech Configuration</option>
                        <option value="46" >VAV Configuration - WFO</option>
                        <option value="44" >Core Support</option>
                        <option value="19" >DPA Configuration</option>
                        <option value="17" >WFM Configuration</option>
                        <option value="51" >PM Configuration</option>
                        <option value="42" >Rec & Arch Configuration</option>
                        <option value="28" >Advanced Support</option>
                        <option value="14" >V11 Migration</option>
                        <option value="45" >IEM Configuration</option>
                        <option value="56" >VAV Configuration - VFC</option>
                        <option value="57" >VFC</option>
                        <option value="59" >VFC Latest Kit</option>
                        <option value="2" >15.1 to 15.2 SBS Migration</option>
                        <option value="54" >WM Installation</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="60" >IvaPro</option>
                    </>
                );
                return environmentDisplay;
                

            case "VCS":
                environmentDisplay = (
                    <option value="36">VCS MT</option>
                );
                return environmentDisplay;
                

            case "OEM":
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="35" >Blue (ST) HFR5</option>
                        <option value="64" >ODF</option>
                        <option value="31" >Green (POC)</option>
                        <option value="34" >Demo</option>
                        <option value="1" >Core Install</option>
                        <option value="26" >EMRM</option>
                        <option value="21" >Authentication</option>
                        <option value="49" >Security (TLS)</option>
                        <option value="10" >Security (KMS)</option>
                        <option value="8" >Text-Config</option>
                        <option value="3" >SAML</option>
                        <option value="15" >Speech Configuration</option>
                        <option value="46" >VAV Configuration - WFO</option>
                        <option value="44" >Core Support</option>
                        <option value="19" >DPA Configuration</option>
                        <option value="17" >WFM Configuration</option>
                        <option value="51" >PM Configuration</option>
                        <option value="42" >Rec & Arch Configuration</option>
                        <option value="28" >Advanced Support</option>
                        <option value="14" >V11 Migration</option>
                        <option value="45" >IEM Configuration</option>
                        <option value="56" >VAV Configuration - VFC</option>
                        <option value="57" >VFC</option>
                        <option value="59" >VFC Latest Kit</option>
                        <option value="2" >15.1 to 15.2 SBS Migration</option>
                        <option value="54" >WM Installation</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="60" >IvaPro</option>
                    </>
                );
                return environmentDisplay;

            case "EMEAPreSales":
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="34" >Demo</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="64" >ODF</option>
                        <option value="31" >Green (POC)</option>
                        <option value="38" >EM Demo</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="54" >WM Installation</option>
                        <option value="60" >IvaPro</option>
                    </>
                );
                return environmentDisplay;

            case "APACPreSales":
                environmentDisplay = (
                    <>
                        <option value="61" >Earth (MT) 2020R1</option>
                        <option value="4" >Earth (MT) HFR7</option>
                        <option value="40" >Earth (MT) HFR5</option>
                        <option value="62" >Blue (ST) 2020R1</option>
                        <option value="5" >Blue (ST) HFR7</option>
                        <option value="35" >Blue (ST) HFR5</option>
                        <option value="34" >Demo</option>
                        <option value="65" >Korea Demo</option>
                        <option value="64" >ODF</option>
                        <option value="31" >Green (POC)</option>
                        <option value="38" >EM Demo</option>
                        <option value="39" >EG-Work Manager</option>
                        <option value="1" >Core Install</option>
                        <option value="26" >EMRM</option>
                        <option value="21" >Authentication</option>
                        <option value="49" >Security (TLS)</option>
                        <option value="10" >Security (KMS)</option>
                        <option value="8" >Text-Config</option>
                        <option value="3" >SAML</option>
                        <option value="15" >Speech Configuration</option>
                        <option value="46" >VAV Configuration - WFO</option>
                        <option value="44" >Core Support</option>
                        <option value="19" >DPA Configuration</option>
                        <option value="17" >WFM Configuration</option>
                        <option value="51" >PM Configuration</option>
                        <option value="42" >Rec & Arch Configuration</option>
                        <option value="28" >Advanced Support</option>
                        <option value="14" >V11 Migration</option>
                        <option value="52" >Breakfix</option>
                        <option value="54" >WM Installation</option>
                        <option value="56" >VAV Configuration - VFC</option>
                        <option value="57" >VFC</option>
                        <option value="60" >IvaPro</option>
                    </>
                );
                return environmentDisplay;

                default:
                    return(<><option value="" selected >Error</option></>);
        }
    }

    //Rewriting schedule choices
    export function getScheduleDisplay(group){
        let scheduleDisplay;
        switch(group){
            case "APAC":
                scheduleDisplay = (
                    <>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                    </>
                ); //TBD
                return scheduleDisplay;

            case "VCS":
                scheduleDisplay = (
                    <>
                        <option value="alwayson">24/7</option>
                    </>
                );
                return scheduleDisplay;

            case "APACPreSales":
                scheduleDisplay = (
                    <>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                        <option value="nostartup-10pm-Israel">GMT+3 (Israel)</option>
                        <option value="nostartup-10pm-Singapore">GMT+8 (Singapore)</option>
                        <option value="nostartup-10pm-Moscow">GMT+3 (Moscow)</option>
                    </>
                );
                return scheduleDisplay;
    
            case "ISV":
                scheduleDisplay = (
                    <>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                        <option value="nostartup-10pm-Israel">GMT+3 (Israel)</option>
                        <option value="nostartup-10pm-Singapore">GMT+8 (Singapore)</option>
                        <option value="nostartup-10pm-Moscow">GMT+3 (Moscow)</option>
                        <option value="nostartup-10pm-EST">GMT-5 (EST)</option>
                        <option value="nostartup-10pm-PST">GMT-8 (Pacific)</option>
                        <option value="nostartup-10pm-GMT">GMT (UK)</option>
                    </>
                );
                return scheduleDisplay;        
        
            default:
                scheduleDisplay = (
                    <>
                        <option value="nostartup-10pm-EST">GMT-5 (EST)</option>
                        <option value="nostartup-10pm-PST">GMT-8 (Pacific)</option>
                        <option value="nostartup-10pm-GMT">GMT (UK)</option>
                        <option value="nostartup-10pm-Israel">GMT+3 (Israel)</option>
                        <option value="nostartup-10pm-Singapore">GMT+8 (Singapore)</option>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                    </>
                );
                return scheduleDisplay;
        }
    }

    export function getInternalIDLabel(group){
        if (group === "PreSales" || group === "APAC" || group === "EMEAPreSales" ||
        group === "APACPreSales")
            return ("SFDC ID");
        else if (group === "VCS")
            return("Customer");
        else 
            return("Internal ID (SO#)");
    }

    export function getReasonDisplay(group){
        let reasonDisplay;
        if (group === "PreSales" || group === "EMEAPreSales" || group === "APACPreSales"){
            reasonDisplay = (
                <>
                    <option value="Demo">Demo</option>
                    <option value="Internal Demo">Internal Demo</option>
                </>
            );
        }
        else{
            reasonDisplay = (
                <>
                    <option value="Customer Training">Customer Training</option>
                    <option value="Verint U">Verint U</option>
                    <option value="Demo">Demo</option>
                    <option value="Internal Demo">Internal Demo</option>
                    <option value="Solution Research">Solution Research</option>
                    <option value="Self-Enablement">Self-Enablement</option>
                    <option value="Internal Training">Internal Training</option>
                    <option value="Partner Demo Lab">Partner Demo Lab</option>
                    <option value="Partner Lab">Partner Lab</option>
                    <option value="Self-Paced Re-Request">Self-Paced Re-Request</option>
                    <option value="Demo Development">Demo Development</option>
                    <option value="Lab Development">Lab Development</option>
                    <option value="Course Development">Course Development</option>
                    <option value="Engage">Engage</option>
                </>
            );
        }
        return reasonDisplay;
    }

    export function setInitialDisplay(group){
        if (group === "PreSales" || group === "APAC" || group === "EMEAPreSales" ||
        group === "APACPreSales")
            return true;
        // setHours(0); -> reflected in prop's initialized values
        // // TODO: This has been commented out while the Text Analytics code is being developed
        // document.getElementById("TextAnalytics").style.display = "none";
        // document.getElementById("TextAnalyticsRow").style.display = "none";
    }

    export function setHideBillable(group){
        return (group === "PreSales" || group === "EMEAPreSales" || group === "APACPreSales")
    }

