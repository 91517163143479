import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

/* Set the value of EFM checkbox */
function EFMCheckbox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(should_display_efm_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    // If selected environment is cannot use the checkbox, hide
    if(should_display_efm_checkbox(props) === false){
        return null
    }

    return <>
        {/*  EFM Checkbox  */}
        <div className="row" id= "EFMRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="EFM_label"className="input_label1" forhtml="EFM">EFM <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Select this option if you require </div>
                <div>EFM capabiility. </div></div>

        </Popover></label>
                </div>
                
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="EFM" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                        name="EFM" type = "checkbox" value = {checked}  onChange={(e)=>{setChecked(!checked)}}  />
                </div>
            </div>
    </>    
}

export default EFMCheckbox;
function should_display_efm_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].EFM_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
// function display_efm_checkbox(template_id){
//     // A list of template ids for template ids that can deploy this checkbox
//     var allowed_template_ids = ['62', '87'];
    
//     // If current template_id is in the list of template ids, display checkbox
//     if(allowed_template_ids.includes(template_id)){
//         return true
//     }
//     else{
//         return false
//     }

// }