import React, {useEffect,useState} from 'react';
import Popover from "@material-ui/core/Popover";
function TandCCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    
    const open = Boolean(anchorEl);
    // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_TandC_checkbox(props) === false){
            props.setTandCSelected(false);
        }  
        if(props.environmentSelected === '64'){
            setChecked(true)
            setIsDisabled(true)
        }
        else{
            setChecked(props.TandCSelected)
            setIsDisabled(props.TandCSelectedue)
        }
    })

    // If selected environment is cannot use the checkbox, hide the checkbox
    if(should_display_TandC_checkbox(props) === false){
        return null
    }
    
    return <>
        {/*  TandC Checkbox */}
        <div style={{}} className="row" id= "TandCRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="TandC_label"className="input_label1" forhtml="TandC">Customer Terms & Conditions <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>By selecting this option the system will inject agreed upon legal T&Cs.</div>
                <div>That upon the first login of each day, will display our T&Cs and </div>
                <div>require acceptance in order to use the environment. Thus allowing </div>
                <div>these types of labs to be given to customers to try the product out.</div>
                <div>Note: Under no circumstances must the customer put sensitive data </div>
                <div>into the system. This is also laid out in the T&Cs during login.</div>
                </div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="TandC" disabled={isDisabled} checked={checked} style={{border: '1px solid #ccc', borderRadius: '4px'}}
                        name="TandC" type = "checkbox" value = {props.TandCSelected} 
                        onChange={(e) => {props.setTandCSelected(!props.TandCSelected);}}/>

                </div>
            </div>
    </>        
}

export default TandCCheckbox;

function should_display_TandC_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].TermsandCondition === true ){
                return true
            }
            else 
            {
                return false
            }
        }      
    }
    return false
}

