import React, {useEffect,useState} from 'react';
import Popover from "@material-ui/core/Popover";

function FriendlyURLCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    ////If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_url_checkbox(props) === false){
            props.seturlChecked(false)
        }        
    }, [props.environmentSelected])

     // If selected environment is cannot use the checkbox, hide
     //If selected environment is cannot use the checkbox, hide
     if(should_display_url_checkbox(props) === false){
        return null
    }

    return <>
        {/* FriendlyURL */}
        <div style={{}} className="row" id= "friendlyurl">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="URL_label"className="input_label1" forhtml="URL">Friendly URL <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <div style={{padding:"12px"}}>
                <div> Select this option if you need </div>
                <div> to create a custom short name </div>
                <div> for a demo</div></div>

      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="FriendlyURL" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                       name="FriendlyURL" type = "checkbox" value = {props.urlchecked}  
                       onChange={(e) => {props.seturlChecked(!props.urlchecked);}}/>

                </div>
            </div>
    </>        
}

export default FriendlyURLCheckbox;

function should_display_url_checkbox(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Friendly_URL === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}