import Popover from "@material-ui/core/Popover";
import React, {useState} from 'react';
function Billable(props) {
        
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

    // If selected environment is cannot use the billable input, hide
    if(display_of_input(props.group) === false){
        return null
    }
    return <>
        {/* Training billable Delivered */}
        <div className="row" id="billableRow">
            <div className="col-sm-1 col-md-3"></div>
            <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                <label id="billable_label"className="input_label1" forhtml="billable">Billable? <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <div style={{padding:"12px"}}>
                <div> If the lab is being used for a purpose</div>
                <div> that is chargeable. Examples include, </div>
                <div> certain training scenarios, development</div>
                <div> work for a customer or any other purpose </div>
                <div> where Verint will receive payment for</div>
                <div>the results derived from the lab.</div></div>
    
    
      </Popover></label>
            </div>
            <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                <select className="input1 form-control" 
                        id="Billable" 
                        name="Billable"
                        required>
                    <option value=""></option>
                    <option value="no">No </option>
                    <option value="yes">Yes </option>
                </select>
            </div>
        </div>
    </>        
}

export default Billable;

function display_of_input(group){
    if(group === "PreSales" || group === "EMEAPreSales" || group === "APACPreSales"|| group === "OEM"){
        return false
    }
    else{
        return true
    }

}