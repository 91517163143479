import React, {useState, useEffect} from 'react';
import Popover from "@material-ui/core/Popover";

function PodNumber(props) {

    const [numberOfPods, setNumberOfPods] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // After every render, if the selected environment changes, check if the environment needs desktops or not.
    // Resets and hides the DesktopNumber component if a technical environment is selected
    useEffect(() => {
        if(display_pod_number_input(props) === false){
            setNumberOfPods(1)
        }        
    }, [props.environmentSelected])

    // Hide DesktopRequired component if the current environment is not an application deployment
    if(display_pod_number_input(props) === false){
        return null
    }

    return <>
        {/* number of pods */}
            <div id="NumberOfPodsRow" className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="NumberOfPods_label"className="input_label1" htmlFor="NumberOfPods">Number of Pods <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Use this option to confirm the number of labs</div>
                <div>you require. For example, if you're delivering</div>
                <div>a training class,you need to specify the number </div>
                <div>of pods required to cover the number of delegates</div>
                <div>you have attending. It is recommended you deploy</div>
                <div>a few extra labs for redundancy purposes.</div></div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="input1 form-control" id="NumberOfPods" style={{ border: '1px solid #ccc', borderRadius: '4px'}}
                        width="150" name="NumberOfPods" type="number" min="1" max="15" value={numberOfPods} required='required' 
                        onChange={(e)=>{setNumberOfPods(e.target.value);}}/>
                </div>
            </div>
    </>       
}

function display_pod_number_input(props){
    // A list of template ids for technical deployment's template ids that require desktops
    // var technical_deployment_template_ids = ['1', '26', '21', '49', '10', '8', '3', '15', '46', '44', '19', '17', 
    // '42', '51', '28', '14', '45', '52', '54', '56', '57', '58', '6', '59', '2', '71','Azure1','Azure2'];
    
    // // If current template_id is in the list of technical template ids, pods are required, else they are not
    // if(technical_deployment_template_ids.includes(template_id)){
    //     return true
    // }
    // else{
    //     return false
    // }
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].display_pod_number_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
export default PodNumber;