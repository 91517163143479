import React, {useEffect,useState} from 'react';
import Popover from "@material-ui/core/Popover";
function LMSCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_LMS_checkbox(props) === false){
            props.setLmsSelected(false);
        }  
    })

    // If selected environment is cannot use the checkbox, hide the checkbox
    if(should_display_LMS_checkbox(props) === false){
        return null
    }

    return <>
        {/*  LMS Checkbox */}
        <div style={{}} className="row" id= "LMSRow">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label id="LMS_label"className="input_label1" forhtml="LMS">Lab Hours <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        >       <div style={{padding:"12px"}}>
                <div>Use this option to determine how </div>
                <div>many hours your lab will last,  </div>
                <div>Once the hour count reaches zero,</div>
                <div>your lab will terminate.</div></div>

        </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-xs-6">
                    <input className="checkmark" id="LMS" style={{border: '1px solid #ccc', borderRadius: '4px'}}
                        name="LMS" type = "checkbox" value = {props.lmsSelected} 
                        onChange={(e) => {props.setLmsSelected(!props.lmsSelected);}}/>

                </div>
            </div>
    </>        
}

export default LMSCheckbox;
function should_display_LMS_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].LMS_Checkbox  === true &&  get_admin_emails(props)){
                return true
            }
            else 
            {
                return false
            }
        }      
    }
    return false
}
//Validate if current user is part of admin emails 
function get_admin_emails(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {        
        if(jsonData.Items[i].template_id === "0" )
        {                  
         
            // Catch error that can be caused by a null user_email 
            try{
                var lower_case_email = props.userEmail.toLowerCase();
            }
            catch{}
            if(jsonData.Items[i].LMS_admin_emails.includes(lower_case_email)){
                return true
            }
            else 
            {
                return false
            }
        }
    }
}
// function display_LMS_checkbox(template_id, user_email){
//     // A list of template ids for template ids that can deploy this checkbox
//     var allowed_template_ids = ['3', '62', '49', '42', '21', '58', '51', '61', '8', '17', '19', '10',
//         '52', '1', '15', '45', '64', '87', '84'];
//     // A list of admin that can deploy LMS environments from the request form
//     var admin_emails = ['devin.ogle@verint.com', 'matt.anscombe@verint.com', 
//         'ullas.gowda@verint.com', 'matt.isv', 'devin.isv', 'jeff.maney@verint.com',
//         'ewa.grzybowska@verint.com', 'todd.snow@verint.com', 'rob.hornbuckle@verint.com',
//         'david.stephens@verint.com','priyadarshini.b@verint.com','arpita.v@verint.com'
//     ];

//     // Catch error that can be caused by a null user_email 
//     try{
//         var lower_case_email = user_email.toLowerCase();
//     }
//     catch{}

//     // If current template_id is in the list of template ids, and the current user is an admin
//         //  display checkbox.
//         // /&& (admin_emails.includes(lower_case_email))
//     if((allowed_template_ids.includes(template_id)) && (admin_emails.includes(lower_case_email)) ){
//         console.log("LMS")
//         return true
//     }
//     else{
//         console.log("LMS not")
//         return false
//     }

// }
