import Popover from "@material-ui/core/Popover";
import React, {useState} from 'react';

function Reason(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    //const [reason, setReason] = useState(null)
    var reasonDisplay = get_reason_display(props.group);

    return <>
        {/* Class for reason selection */}
        <div className="row">
                <div className="col-sm-1 col-md-3"></div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <label className="input_label1" forhtml="ReasonName">Reason <i class="fa fa-info-circle" aria-hidden="true" onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}></i><Popover 
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
          
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
           <div style={{padding:"12px"}}>
                <div> Select from the drop down the </div>
                <div> reason code for the lab.</div></div>
    
      </Popover></label>
                </div>
                <div className="col-7 col-sm-4 col-md-3 col-lg-3">
                    <select className="input1 form-control " name="ReasonName" id="ReasonName" required>
                        {reasonDisplay}
                    </select>
                </div>
            </div>
    </>       
}

export default Reason;


function get_reason_display(group){
    let reasonDisplay;
    if (group === "PreSales" || group === "EMEAPreSales" || group === "APACPreSales"){
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Customer Trial">Customer Trial</option>
                <option value="Demo">Demo</option>
                <option value="Internal Demo">Internal Demo</option>
                <option value="Partner Demo Lab">Partner Demo Lab</option>
                <option value="Partner Lab">Partner Lab</option>
                <option value="Demo Development">Demo Development</option>
                <option value="Engage">Engage</option>
            </>
        );
    }
    else if (group == 'ISV'){
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Customer Trial">Customer Trial</option>
                <option value="ISV Development">ISV Development</option>
                <option value="Product Development">Product Development</option>
                <option value="Partner Lab">Partner Lab</option>
                <option value="Partner Demo Lab">Partner Demo Lab</option>
                <option value="Enage">Engage</option>
                <option value="SI Work">SI Work</option>
            </>
        );
    }
    else{
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Customer Trial">Customer Trial</option>
                <option value="Customer Training">Customer Training</option>
                <option value="Verint U">Verint U</option>
                <option value="Product Development">Product Development</option>
                <option value="Demo">Demo</option>
                <option value="Internal Demo">Internal Demo</option>
                <option value="Solution Research">Solution Research</option>
                <option value="Self-Enablement">Self-Enablement</option>
                <option value="Internal Training">Internal Training</option>
                <option value="Partner Demo Lab">Partner Demo Lab</option>
                <option value="Partner Lab">Partner Lab</option>
                <option value="Self-Paced Re-Request">Self-Paced Re-Request</option>
                <option value="Demo Development">Demo Development</option>
                <option value="Lab Development">Lab Development</option>
                <option value="Course Development">Course Development</option>
                <option value="Engage">Engage</option>
                <option value="ISV Development">ISV Development</option>
                <option value="SI Work">SI Work</option>
                <option value="Support Beta Testing">Support Beta Testing</option>
                <option value="CSM">CSM</option>
            </>
        );
    }
    return reasonDisplay;
}