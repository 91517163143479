import React, { useState, useEffect } from "react";

function CloudWFEDemoCheckbox(props) {
  const [checked, setChecked] = useState(false);

  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (display_cloud_wfe_demo_checkbox(props) === false) {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  // If selected environment is cannot use the checkbox, hide
  if (display_cloud_wfe_demo_checkbox(props) === false) {
    return null;
  }

  return (
    <>
      {/*  MS Teams Live Recording  */}
      <div style={{}} className="row" id="CloudWFEDemoRow">
        <div className="col-sm-1 col-md-3"></div>
        <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
          <label
            id="CloudWFEDemo_label"
            className="input_label1"
            forhtml="CloudWFEDemo"
          >
            Additional Speech App
          </label>
        </div>
        <div className="col-7 col-sm-4 col-md-3 col-xs-6">
          <input disabled
            className="checkmark"
            id="CloudWFEDemo"
            style={{ border: "1px solid #ccc", borderRadius: "4px" }}
            name="CloudWFEDemo"
            type="checkbox" checked
            value={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CloudWFEDemoCheckbox;

function display_cloud_wfe_demo_checkbox(props) {
  var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].cloud_wfe_demo_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}