import React, { useState, useEffect } from "react";

function MSTeamsAzureCheckbox(props) {
  const [checked, setChecked] = useState(false);

  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (display_msteams_checkbox(props.environmentSelected) === false) {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  // If selected environment is cannot use the checkbox, hide
  if (display_msteams_checkbox(props.environmentSelected) === false) {
    return null;
  }

  return (
    <>
      {/*  MS Teams Live Recording  */}
      <div style={{}} className="row" id="MSTeamsLiveRecordingRow">
        <div className="col-sm-1 col-md-3"></div>
        <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2">
          <label
            id="MSTeamsLiveRecording_label"
            className="input_label1"
            forhtml="MSTeamsLiveRecording"
          >
            Teams Recording
          </label>
        </div>
        <div className="col-7 col-sm-4 col-md-3 col-xs-6">
          <input disabled
            className="checkmark"
            id="MSTeamsLiveRecording"
            style={{ border: "1px solid #ccc", borderRadius: "4px" }}
            name="MSTeamsLiveRecording"
            type="checkbox" checked
            value={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default MSTeamsAzureCheckbox;

function display_msteams_checkbox(template_id) {
  // A list of template ids for template ids that can deploy this checkbox
  var allowed_template_ids = ["Azure1", "Azure2"];

  // If current template_id is in the list of template ids, display checkbox
  if (allowed_template_ids.includes(template_id)) {
    return true;
  } else {
    return false;
  }
}